<template>
  <div class="container">
    <rs-row :gutter="50">
      <rs-col :span="6" v-for="(item,index) in list" :key="index">
        <div class="card-panel" @click="openSysSetting(item)">
          <div>
            <h4 class="title">{{item.title}}</h4>
          </div>
          <div>
            <p class="desc">{{item.desc}}</p>
          </div>
        </div>
      </rs-col>
    </rs-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // configGroup值范围:1.登录页 2.首页配置 3.系统配置 4.会员微信小程序 5.商户微信小程序 6.微信公众号 7.企业微信 8.钉钉
      list:[
        { 
          typeIndex:0,
          type:4,
          title:'会员微信小程序',
          desc:'会员微信小程序APPID，APPSecret配置'
        },
        {
          typeIndex:1,
          type:5,
          title:'商户微信小程序',
          desc:'商户微信小程序APPID，APPSecret配置'
        },
        {
          typeIndex:2,
          type:6,
          title:'微信公众号',
          desc:'微信公众号APPID，APPSecret配置'
        },
        {
          typeIndex:3,
          type:7,
          title:'企业微信',
          desc:'微信公众号APPID，APPSecret、agentId配置'
        },
        {
          typeIndex:4,
          type:8,
          title:'钉钉',
          desc:'钉钉APPID，APPSecret配置'
        }
      ]
    }
  },
  mounted() {
  },
  methods: {
    openSysSetting(item){
      this.$router.push(`/thirdConfigInfo?typeIndex=${item.typeIndex}&type=${item.type}`)
    }
  }
}
</script>
<style scoped >
.container {
  margin: 20px;
}
.item{
  margin-bottom: 20px;
}
.card-panel {
  height: 110px;
  cursor: pointer;
  font-size: 12px;
  position: relative;
  overflow: hidden;
  color: #666;
  background: #fff;
  -webkit-box-shadow: 4px 4px 40px rgb(0 0 0 / 5%);
  box-shadow: 4px 4px 40px rgb(0 0 0 / 5%);
  border-color: rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
  border-radius: 4px;
}
.title {
  padding: 10px;
  text-align: center;
  font-size: 18px;
  background-color: #3370ff;
  color: #fff;
  height: 45px;
}
.desc {
  text-align: center;
  font-size: 14px;
  padding: 12px;
}
</style>