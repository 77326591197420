var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "rs-row",
        { attrs: { gutter: 50 } },
        [
          _c("rs-col", { attrs: { span: 6 } }, [
            _c(
              "div",
              { staticClass: "card-panel", on: { click: _vm.openSysSetting } },
              [
                _c("div", [
                  _c("h4", { staticClass: "title" }, [_vm._v("登录页配置")]),
                ]),
                _c("div", [
                  _c("p", { staticClass: "desc" }, [
                    _vm._v("登录页的logo，背景图等"),
                  ]),
                ]),
              ]
            ),
          ]),
          _c("rs-col", { attrs: { span: 6 } }, [
            _c(
              "div",
              { staticClass: "card-panel", on: { click: _vm.homeSetting } },
              [
                _c("div", [
                  _c("h4", { staticClass: "title" }, [_vm._v("首页配置")]),
                ]),
                _c("div", [
                  _c("p", { staticClass: "desc" }, [
                    _vm._v("系统首页的logo，系统首页标题，浏览器图标等"),
                  ]),
                ]),
              ]
            ),
          ]),
          _vm.type == "system"
            ? _c("rs-col", { attrs: { span: 6 } }, [
                _c(
                  "div",
                  { staticClass: "card-panel", on: { click: _vm.mapSetting } },
                  [
                    _c("div", [
                      _c("h4", { staticClass: "title" }, [_vm._v("地图配置")]),
                    ]),
                    _c("div", [
                      _c("p", { staticClass: "desc" }, [
                        _vm._v("系统内地图类型及KEY等"),
                      ]),
                    ]),
                  ]
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }