var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "rs-row",
        { attrs: { gutter: 50 } },
        _vm._l(_vm.list, function (item, index) {
          return _c("rs-col", { key: index, attrs: { span: 6 } }, [
            _c(
              "div",
              {
                staticClass: "card-panel",
                on: {
                  click: function ($event) {
                    return _vm.openSysSetting(item)
                  },
                },
              },
              [
                _c("div", [
                  _c("h4", { staticClass: "title" }, [
                    _vm._v(_vm._s(item.title)),
                  ]),
                ]),
                _c("div", [
                  _c("p", { staticClass: "desc" }, [_vm._v(_vm._s(item.desc))]),
                ]),
              ]
            ),
          ])
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }