<template>
  <div class="container">
    <rs-row :gutter="50">
      <rs-col :span="6">
        <div class="card-panel" @click="openSysSetting">
          <div>
            <h4 class="title">登录页配置</h4>
          </div>
          <div>
            <p class="desc">登录页的logo，背景图等</p>
          </div>
        </div>
      </rs-col>
      <rs-col :span="6">
        <div class="card-panel" @click="homeSetting">
          <div>
            <h4 class="title">首页配置</h4>
          </div>
          <div>
            <p class="desc">系统首页的logo，系统首页标题，浏览器图标等</p>
          </div>
        </div>
      </rs-col>
      <rs-col :span="6" v-if="type=='system'">
        <div class="card-panel" @click="mapSetting">
          <div>
            <h4 class="title">地图配置</h4>
          </div>
          <div>
            <p class="desc">系统内地图类型及KEY等</p>
          </div>
        </div>
      </rs-col>
    </rs-row>
    <!-- <rs-dialog title="登录页配置" :visible.sync="disvisable" width="60%">
      <rs-form :model="itemForm" label-width="140px" style="width: 70%" :show-message="false" :rules="rules" ref="loginForm">
        <rs-form-item label="登录页标题：" prop="customLoginTitle">
          <div class="flexCls">
            <rs-input v-model="itemForm.customLoginTitle" maxlength="10" placeholder="请输入登录页标题"></rs-input>
            <rs-tooltip placement="top" style="margin-left: 12px">
              <div slot="content"></div>
              <i class="rs-icon-question"></i>
            </rs-tooltip>
          </div>
        </rs-form-item>

        <rs-form-item label="登录页背景图：" class="form-item" prop="customLoginBgimg">
          <div class="flexCls" style="margin: 8px 0">
            <rs-radio-group v-model="itemForm.loginImgType">
              <rs-radio :label="0">默认</rs-radio>
              <rs-radio :label="1">自定义</rs-radio>
            </rs-radio-group>
            <rs-tooltip placement="top" style="margin-left: 12px">
              <div slot="content"></div>
              <i class="rs-icon-question"></i>
            </rs-tooltip>
          </div>
          <rs-upload
            class="avatar-uploader"
            :action="uploadUrl"
            :show-file-list="false"
            :on-success="handleAvatarSuccess.bind(this, 'customLoginBgimg')"
            :before-upload="beforeAvatarUpload"
          >
            <img v-if="itemForm.customLoginBgimg" :src="itemForm.customLoginBgimg" class="avatar" />
            <i v-else class="rs-icon-plus avatar-uploader-icon"></i>
          </rs-upload>
        </rs-form-item>

        <rs-form-item label="登录页LOGO图：" class="form-item" prop="customLoginLogo">
          <div class="flexCls" style="margin: 8px 0">
            <rs-radio-group v-model="itemForm.logoImgType">
              <rs-radio :label="0">默认</rs-radio>
              <rs-radio :label="1">自定义</rs-radio>
            </rs-radio-group>
            <rs-tooltip placement="top" style="margin-left: 12px">
              <div slot="content"></div>
              <i class="rs-icon-question"></i>
            </rs-tooltip>
          </div>
          <rs-upload
            class="avatar-uploader"
            :action="uploadUrl"
            :show-file-list="false"
            :on-success="handleAvatarSuccess.bind(this, 'customLoginLogo')"
            :before-upload="beforeAvatarUpload"
          >
            <img v-if="itemForm.customLoginLogo" :src="itemForm.customLoginLogo" class="avatar" />
            <i v-else class="rs-icon-plus avatar-uploader-icon"></i>
          </rs-upload>
        </rs-form-item>
        <rs-form-item label="备案/许可证号：" class="form-item" prop="baNo">
          <rs-input v-model="itemForm.baNo" maxlength="128" placeholder="备案/许可证号"></rs-input>
          <rs-input v-model="itemForm.baUrl" maxlength="256" placeholder="备案链接地址" style="margin-top: 15px"></rs-input>
        </rs-form-item>
      </rs-form>
      <span slot="footer" class="dialog-footer">
        <rs-button type="primary" @click="confrimDialog">保存</rs-button>
      </span>
    </rs-dialog> -->
    <!-- <rs-dialog title="首页配置" :visible.sync="disvisableHome" width="60%"> 
        <div style="max-height:500px;overflow-y:auto">
        <rs-form :model="itemForm1" label-width="160px" style="width: 70%" :show-message="false" :rules="rules1">
            <rs-form-item label="系统首页标题：" class="form-item" prop="customIndexTitle">
            <div class="flexCls">
                <rs-input v-model="itemForm1.customIndexTitle" placeholder="请输入首页标题" maxlength="10"></rs-input>
                <rs-tooltip placement="top" style="margin-left: 12px">
                <div slot="content"></div>
                <i class="rs-icon-question"></i>
                </rs-tooltip>
            </div>
            </rs-form-item>
            <rs-form-item label="首页LOGO：" class="form-item" prop="customIndexLogo">
            <div class="flexCls" style="margin: 8px 0">
                <rs-radio-group v-model="itemForm1.logoImgType">
                <rs-radio :label="0">默认</rs-radio>
                <rs-radio :label="1">自定义</rs-radio>
                </rs-radio-group>
                <rs-tooltip placement="top" style="margin-left: 12px">
                <div slot="content"></div>
                <i class="rs-icon-question"></i>
                </rs-tooltip>
            </div>
            <rs-upload
                class="avatar-uploader"
                :action="uploadUrl"
                :show-file-list="false"
                :on-success="handleAvatarSuccess.bind(this, 'customIndexLogo')"
                :before-upload="beforeAvatarUpload"
            >
                <img v-if="itemForm1.customIndexLogo" :src="itemForm1.customIndexLogo" class="avatar" />
                <i v-else class="rs-icon-plus avatar-uploader-icon"></i>
            </rs-upload>
            </rs-form-item>
            <rs-form-item label="浏览器系统标题：" class="form-item" prop="customHtmlHeadTitle">
            <div class="flexCls">
                <rs-input v-model="itemForm1.customHtmlHeadTitle" placeholder="请输入首页标题" maxlength="10"></rs-input>
                <rs-tooltip placement="top" style="margin-left: 12px">
                <div slot="content"></div>
                <i class="rs-icon-question"></i>
                </rs-tooltip>
            </div>
            </rs-form-item>
            <rs-form-item label="浏览器系统图标：" class="form-item" prop="customHtmlHeadLogo">
            <div class="flexCls" style="margin: 8px 0">
                <rs-radio-group v-model="itemForm1.browserLogoType">
                <rs-radio :label="0">默认</rs-radio>
                <rs-radio :label="1">自定义</rs-radio>
                </rs-radio-group>
                <rs-tooltip placement="top" style="margin-left: 12px">
                <div slot="content"></div>
                <i class="rs-icon-question"></i>
                </rs-tooltip>
            </div>
            <rs-upload
                class="avatar-uploader"
                :action="uploadUrl"
                :show-file-list="false"
                :on-success="handleAvatarSuccess.bind(this, 'customHtmlHeadLogo')"
                :before-upload="beforeAvatarUpload"
            >
                <img v-if="itemForm1.customHtmlHeadLogo" :src="itemForm1.customHtmlHeadLogo" class="avatar" />
                <i v-else class="rs-icon-plus avatar-uploader-icon"></i>
            </rs-upload>
            </rs-form-item>
            <rs-form-item label="工作台-商户小程序：" class="form-item" prop="customMerchantImg">
            <rs-upload
                class="avatar-uploader"
                :action="uploadUrl"
                :show-file-list="false"
                :on-success="handleAvatarSuccess.bind(this, 'customMerchantImg')"
                :before-upload="beforeAvatarUpload"
            >
                <img v-if="itemForm1.customMerchantImg" :src="itemForm1.customMerchantImg" class="avatar" />
                <i v-else class="rs-icon-plus avatar-uploader-icon"></i>
                <div class="delCls" v-if="itemForm1.customMerchantImg">
                <i class="rs-icon-delete" @click.stop="delApplet('customMerchantImg')"></i>
                </div>
            </rs-upload>
            </rs-form-item>
            <rs-form-item label="工作台-会员小程序：" class="form-item">
            <rs-upload
            class="avatar-uploader"
            :action="uploadUrl"
            :show-file-list="false"
            :on-success="handleAvatarSuccess.bind(this, 'customAppletVipImg')"
            :before-upload="beforeAvatarUpload"
            >
            
            <img v-if="itemForm1.customAppletVipImg" :src="itemForm1.customAppletVipImg" class="avatar" />
            <i v-else class="rs-icon-plus avatar-uploader-icon"></i>
            <div class="delCls" v-if="itemForm1.customAppletVipImg">
                <i class="rs-icon-delete" @click.stop="delApplet('customAppletVipImg')"></i>
            </div>
            </rs-upload>
        </rs-form-item>
        
        </rs-form>
            </div>
            <span slot="footer" class="dialog-footer">
            <rs-button type="primary" @click="confrimDialog1">保存</rs-button>
        </span>
    </rs-dialog> -->
  </div>
</template>
<script>
import Api from '@/api/imgSetter.js'

export default {
  data() {
    return {
      disvisable: false,
      disvisableHome: false,
     
      imageUrl: ''
    }
  },
  props: {
      type: {
        type: String,
        default: 'system'
      }
  },
  mounted() {
   
  },
  methods: {
    openSysSetting() {
         this.$router.push(`/sysSettingDetail?type=system&pageType=${this.type}`)
    },
    homeSetting(){
        this.$router.push(`/sysSettingDetail?type=home&pageType=${this.type}`)
    },
    mapSetting(){
        this.$router.push('/mapSettingDetail?type=map')
    },
  }
   
}
</script>
<style scoped >
.container {
  margin: 20px;
}
.card-panel {
  height: 110px;
  cursor: pointer;
  font-size: 12px;
  position: relative;
  overflow: hidden;
  color: #666;
  background: #fff;
  -webkit-box-shadow: 4px 4px 40px rgb(0 0 0 / 5%);
  box-shadow: 4px 4px 40px rgb(0 0 0 / 5%);
  /* border:1px solid rgba(0,0,0,.05); */
  border-color: rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
  border-radius: 4px;
}
.title {
  padding: 10px;
  text-align: center;
  font-size: 18px;
  background-color: #3370ff;
  color: #fff;
  height: 45px;
}
.desc {
  text-align: center;
  font-size: 14px;
  padding: 12px;
}
.flexCls {
  display: flex;
  align-items: center;
}
.avatar-uploader /deep/ .rs-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader /deep/ .rs-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}
.avatar {
  width: 120px;
  height: 120px;
  display: block;
}
/deep/ .rs-button {
  height: 32px;
  line-height: 32px;
  padding: 0;
}
.rs-upload:hover .delCls {
  display: block;
}
.delCls {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s;
  display: none;
}
.delCls i {
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>