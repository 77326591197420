<template>
  <div class="container initial">
    <rs-tabs v-model="activeName" @tab-click="tabChange" style="height: 100%">
      <rs-tab-pane label="系统配置" name="system">
        <system-setting type="system"></system-setting>
      </rs-tab-pane>
      <rs-tab-pane label="三方平台配置" name="third">
        <thirdConfig />
      </rs-tab-pane>
      <rs-tab-pane label="支付配置" name="pay">
        <pay-setting v-if="activeName == 'pay'"></pay-setting>
      </rs-tab-pane>
      <rs-tab-pane label="商户平台配置" name="business">
        <system-setting type="business"></system-setting>
      </rs-tab-pane>
    </rs-tabs>
  </div>
</template>
<script>
import systemSetting from './components/systemSetting.vue'
import paySetting from './components/paySetting.vue'
import thirdConfig from './components/thirdConfig'
import Bus from '@/utils/eventBus'
export default {
  components: { systemSetting, paySetting, thirdConfig },
  data() {
    return {
      activeName: 'system'
    }
  },
  methods: {
    tabChange() {}
  },
  created(){
    if(this.$route.params.from){
      this.activeName = this.$route.params.from
    }
  },
  mounted(){
  }
}
</script>
<style lang="less" scoped>
.container {
  // padding: 10px;
}
</style>
