var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "div",
        { staticClass: "form-wrap" },
        [
          _c("renderForm", {
            ref: "renderFormRef",
            attrs: {
              list: _vm.ruleForm,
              labelWidth: "80",
              formData: _vm.queryForm,
              dispalyColumn: false,
              rules: _vm.rules,
            },
            on: {
              "update:formData": function ($event) {
                _vm.queryForm = $event
              },
              "update:form-data": function ($event) {
                _vm.queryForm = $event
              },
            },
            scopedSlots: _vm._u([
              {
                key: "mallCode",
                fn: function () {
                  return [
                    _c(
                      "rs-form-item",
                      { attrs: { label: "项目名称", prop: "mallCode" } },
                      [
                        _c("rs-select-mall", {
                          ref: "mallRef",
                          attrs: {
                            host: "/api/user",
                            clearable: false,
                            mustFlag: false,
                          },
                          on: { change: _vm.changeMall },
                          model: {
                            value: _vm.queryForm.mallCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm, "mallCode", $$v)
                            },
                            expression: "queryForm.mallCode",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "operation",
                fn: function () {
                  return [
                    _c(
                      "rs-button",
                      {
                        attrs: { type: "primary", size: "small" },
                        on: { click: _vm.search },
                      },
                      [_vm._v("查询")]
                    ),
                    _c(
                      "rs-button",
                      {
                        attrs: { size: "small", type: "text" },
                        on: { click: _vm.reset },
                      },
                      [_vm._v("重置")]
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "renderTable",
        {
          attrs: { list: _vm.tableData, border: "", columns: _vm.tableColumns },
        },
        [
          _c(
            "div",
            { attrs: { slot: "action" }, slot: "action" },
            [
              _c(
                "rs-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.add },
                },
                [_vm._v("新 增")]
              ),
            ],
            1
          ),
          _c("rs-table-column", {
            attrs: { slot: "appType", label: "APP类型", width: "180" },
            slot: "appType",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          scope.row.defaultMall ? "会员小程序" : "商户小程序"
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("rs-table-column", {
            attrs: {
              slot: "operation",
              label: "操作",
              width: "180",
              fixed: "right",
              align: "center",
            },
            slot: "operation",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "rs-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.toEdit(scope.row)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("rs-pagination", {
        attrs: {
          "current-page": _vm.pageNo,
          "page-sizes": [10, 20, 50, 100],
          "page-size": _vm.pageSize,
          layout: "prev, pager, next,sizes, jumper",
          total: _vm.total,
          background: "",
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }