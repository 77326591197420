<template>
  <div class="container">
    <!-- 搜索表单 -->
    <div class="form-wrap">
      <renderForm :list="ruleForm" ref="renderFormRef" labelWidth="80" :formData.sync="queryForm" :dispalyColumn="false" :rules="rules">
        <template v-slot:mallCode>
          <rs-form-item label="项目名称" prop="mallCode">
            <rs-select-mall
              :host="'/api/user'"
              v-model="queryForm.mallCode"
              :clearable="false"
              :mustFlag="false"
              ref="mallRef"
              @change="changeMall"
            ></rs-select-mall>
          </rs-form-item>
        </template>
        <template v-slot:operation>
            <rs-button type="primary" size="small" @click="search">查询</rs-button>
              <rs-button  size="small" @click="reset" type="text">重置</rs-button>
        </template>
      </renderForm>
    </div>
    <renderTable :list="tableData" border :columns="tableColumns" >
   <div slot="action">
          <rs-button type="primary" size="small" @click="add">新 增</rs-button>
        </div>
      <rs-table-column label="APP类型" width="180" slot="appType" >
        <template slot-scope="scope">
          <span>{{scope.row.defaultMall?'会员小程序':'商户小程序'}}</span>
        </template>
      </rs-table-column>
      <rs-table-column label="操作" width="180" slot="operation" fixed="right" align="center">
        <template slot-scope="scope">
          <rs-button type="text" @click="toEdit(scope.row)">编辑</rs-button>
        </template>
      </rs-table-column>
    </renderTable>
      <rs-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNo"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          layout="prev, pager, next,sizes, jumper"
          :total="total"
          background
        ></rs-pagination>
  </div>
</template>
<script>
import Api from '@/api/imgSetter.js'
export default {
  data() {
    return {
      ruleForm: [],
      queryForm: {
        mallCode:''
      },
      tableData: [],
      tableColumns: [],
            pageNo: 1,
             pageSize: 10,
             total:0,
             rules:{
                // mallCode:[{required:true,trigger:'blur',message:'请选择项目'}]
             },
       disvisablePay:false
    }
  },
  created() {
       this.uploadUrl = Api.uploadUrl()


       this.getList()
        this.initForm();
  },
  watch: {
    files() {
      this.fileList = [...this.files]
    }
  },
  methods: {
    getList(){
        let params = {
            pageNum:this.pageNo,
            pageSize:this.pageSize,
            mallCode:this.queryForm.mallCode
        }
        Api.findPage(params).then(res=>{
            if(res.data.code == 200){
                this.tableData = res.data.data.list;
                this.total = res.data.data.total
            }
        })
    },
    search(){
       this.pageNo = 1
       this.getList()
    },
    reset(){
     this.queryForm={
       mallCode:''
     }
    },
   handleSizeChange(val) {
      this.pageSize = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.pageNo = val
      this.getList()
    },
    add(){
        this.$router.push('/paySettingDetail?type=add')
    },
    toEdit(row) {
         this.$router.push(`/paySettingDetail?type=edit&rowId=${row.id}`)
    },
    initForm() {
      this.ruleForm = [
        {
          slot: 'mallCode'
        },
        {
          slot: 'operation'
        }
      ]
      this.tableColumns = [
        {
          prop: 'mallName',
          label: '项目',
          width:100
        },
        {
          prop: 'companyName',
          label: '主体公司'
        },{
         slot:'appType'
        },{
          prop: 'appId',
          label: 'APPID'
        },
        {
          prop: 'appSecret',
          label: 'APPSecret'
        },
        {
          prop: 'apiKey',
          label: 'API密钥'
        },
        {
          prop: 'merchantId',
          label: 'merchant_id'
        },
        // {
        //   prop: 'parkNotifyUrl',
        //   label: '停车回调地址',
        //
        // },
        {
          prop: 'updateTime',
          label: '更新时间',
          width:150
        },
        {
          slot: 'operation'
        }
      ]
    },
    changeMall(val) {
      this.queryForm.mallCode = val.mallCode || ''


    },
    query() {},
  }
}
</script>
<style scoped lang="less">
.container {
  padding: 0 10px;
}
/deep/ .tabControlBtnText {
  display: none;
}

</style>
