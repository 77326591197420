var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container initial" },
    [
      _c(
        "rs-tabs",
        {
          staticStyle: { height: "100%" },
          on: { "tab-click": _vm.tabChange },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "rs-tab-pane",
            { attrs: { label: "系统配置", name: "system" } },
            [_c("system-setting", { attrs: { type: "system" } })],
            1
          ),
          _c(
            "rs-tab-pane",
            { attrs: { label: "三方平台配置", name: "third" } },
            [_c("thirdConfig")],
            1
          ),
          _c(
            "rs-tab-pane",
            { attrs: { label: "支付配置", name: "pay" } },
            [_vm.activeName == "pay" ? _c("pay-setting") : _vm._e()],
            1
          ),
          _c(
            "rs-tab-pane",
            { attrs: { label: "商户平台配置", name: "business" } },
            [_c("system-setting", { attrs: { type: "business" } })],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }